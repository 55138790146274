@use "@carbon/react/scss/spacing" as *;
@use "@carbon/react/scss/type" as *;
@use "@carbon/react/scss/breakpoint" as *;
@use "@carbon/react/scss/theme" as *;

@use "./overrides";

.three-leveled-section {
  display: flex;
  flex-direction: row;
  padding: 20px;
  gap: 30px;
  margin: 0px 20px;

  @include breakpoint(sm) {
    flex-direction: column;
    gap: 10px;
    padding: 20px 0px 20px 0px;
  }
  @include breakpoint(lg) {
    flex-direction: row;
    gap: 30px;
    padding: 20px 0px 20px 0px;
  }
  @include breakpoint(md) {
    flex-direction: row;
    gap: 30px;
    padding: 20px 0px 20px 0px;
  }

  .left-section {
    display: flex;
    justify-content: center;
    align-items: center;

    @include breakpoint(sm) {
      justify-content: start;
    }
    @include breakpoint(lg) {
      justify-content: center;
    }
    @include breakpoint(md) {
      justify-content: center;
    }
  }

  .right-section {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    width: 100%;

    .three-contents {
      display: flex;
      flex-direction: column;
      gap: 6px;
      flex-grow: 1;
      width: 80%;

      .title {
        font-size: 17px;
        font-weight: 600;
      }
    }
  }

  &.has-border {
    border-bottom: 1px solid lightgray !important;
  }
}
