@use "@carbon/react/scss/spacing" as *;
@use "@carbon/react/scss/type" as *;
@use "@carbon/react/scss/breakpoint" as *;
@use "@carbon/react/scss/theme" as *;

@use "./overrides";

.loading-view--container {
  // .loader-sm {
  //     wi
  // }
  .loader-md {
    width: 50px;
    height: 50px;
  }
  .loader-lg {
    width: 80px;
    height: 80px;
  }
}
