@use "@carbon/react/scss/spacing" as *;
@use "@carbon/react/scss/type" as *;
@use "@carbon/react/scss/breakpoint" as *;
@use "@carbon/react/scss/theme" as *;

@use "./override";
@use "../../shared-styles" as *;

@use "./ChargebacksSummary/chargebacks-summary";
@use "./CustomersSummary/customers-summary";
@use "./MerchantIssuesSummary/merchant-issues-summary";
@use "./MerchantsSummary/merchants-summary";
@use "./PaymentsSummary/payments-summary";
@use "./PayoutsSummary/payouts-summary";
@use "./RefundsSummary/refunds-summary";
@use "./TopUpsSummary/top-ups-summary";
@use "./TransfersSummary/transfers-summary";

.signup-success-modal {
  .get-started-section {
    margin-top: 30px;
  }
  .get-started-button {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.section-title {
  margin-right: $spacing-08;
  margin-bottom: $spacing-04;
  font-size: 26px;

  @include breakpoint(md) {
    margin-bottom: 0;
  }
}

// .accordion-container {
//   margin-bottom: $spacing-08;
// }

// .accordion-description {
//   margin-top: $spacing-04;
//   font-size: 18px;
// }

// .welcome-accordion {
//   border: 1px solid lightgray;
//   border-left: 5px solid $switchapp-themecolor !important;
//   border-top-right-radius: 5px;
//   border-bottom-right-radius: 5px;
//   background-color: $switchapp-light-theme !important;
//   padding-left: $spacing-08;

//   .cds--accordion__title {
//     @include type-style("productive-heading-04");
//     font-weight: bolder;
//   }
// }

.description-heading {
  @include type-style("productive-heading-03");
  font-weight: bold;
  margin-top: $spacing-08;
}

.switchapp-setup--details {
  margin: auto;
  margin-top: $spacing-06;
  margin-bottom: $spacing-06;
}

.switchapp-setup--icon {
  float: left;
  vertical-align: middle;
  margin-right: $spacing-03;
  height: 100%;
}

.setup-button {
  margin-top: $spacing-04 !important;
}

.chart-row--first {
  @include breakpoint(md) {
    margin-bottom: $spacing-08;
  }
}

.icon-text {
  margin-left: $spacing-02;
  font-size: 14px;
}

.badge-with-icon {
  padding: $spacing-01 $spacing-02;
  border-radius: $spacing-02;
  &.icon-success {
    background-color: rgb(157, 219, 157);

    svg {
      color: green;
    }
  }

  &.icon-danger {
    background-color: rgb(255, 195, 195);

    svg {
      color: red;
    }
  }
}

.edit-chart--icon {
  margin-right: $spacing-02;
}

.reports {
  margin-top: $spacing-08 * 2;
  font-weight: 900;
}

.small--select {
  select {
    padding: 0 10px;
    height: 2rem !important;
  }
}

.summaries-container {
  padding-bottom: 20px;

  .summary-section {
    margin: 0 -5px 15px 0;
    border-radius: 5px;

    @include breakpoint(md) {
      margin: 0 10px 15px 0;
    }

    .summary-section--header {
      padding: 10px;
      margin-bottom: 5px;
      border-bottom: 1px solid lightgray;

      .refresh-icon {
        width: 25px;
        max-height: 25px;
      }
    }

    .summary-section--body {
      padding: 10px;
      margin: 5px;

      .summary-field {
        padding: 1.5px 1px;
        border-radius: 5px;
        background-color: rgba(100, 100, 100, 0.051);
      }
    }
  }
}
