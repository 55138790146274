@font-face {
  font-family: Catamaran-Thin;
  src: local("Catamaran-Thin"),
    url(./assets/fonts/Catamaran-Thin.ttf) format("truetype"); // 'woff2', 'truetype, 'opentype', 'svg', 'embedded-opentype'
  font-weight: 100;
}

@font-face {
  font-family: Catamaran-ExtraLight;
  src: local("Catamaran-ExtraLight"),
    url(./assets/fonts/Catamaran-ExtraLight.ttf) format("truetype");
  font-weight: 200;
}

@font-face {
  font-family: Catamaran-Light;
  src: local("Catamaran-Light"),
    url(./assets/fonts/Catamaran-Light.ttf) format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: Catamaran-Regular;
  src: local("Catamaran-Regular"),
    url(./assets/fonts/Catamaran-Regular.ttf) format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: Catamaran-Medium;
  src: local("Catamaran-Medium"),
    url(./assets/fonts/Catamaran-Medium.ttf) format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: Catamaran-SemiBold;
  src: local("Catamaran-SemiBold"),
    url(./assets/fonts/Catamaran-SemiBold.ttf) format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: Catamaran-Bold;
  src: local("Catamaran-Bold"),
    url(./assets/fonts/Catamaran-Bold.ttf) format("truetype");
  font-weight: 700;
}

@font-face {
  font-family: Catamaran-ExtraBold;
  src: local("Catamaran-ExtraBold"),
    url(./assets/fonts/Catamaran-ExtraBold.ttf) format("truetype");
  font-weight: 800;
}

@font-face {
  font-family: Catamaran-Black;
  src: local("Catamaran-Black"),
    url(./assets/fonts/Catamaran-Black.ttf) format("truetype");
  font-weight: 900;
}

.font-ctm--thin {
  font-family: Catamaran-thin;
}

.font-ctm--extralight {
  font-family: Catamaran-extralight;
}

.font-ctm--light {
  font-family: Catamaran-light;
}

.font-ctm--regular {
  font-family: Catamaran-regular;
}

.font-ctm--medium {
  font-family: Catamaran-medium;
}

.font-ctm--semibold {
  font-family: Catamaran-semibold;
}

.font-ctm--bold {
  font-family: Catamaran-bold;
}

.font-ctm--extrabold {
  font-family: Catamaran-extrabold;
}

.font-ctm--black {
  font-family: Catamaran-black;
}
