@use "@carbon/react/scss/spacing" as *;
@use "@carbon/react/scss/type" as *;
@use "@carbon/react/scss/breakpoint" as *;
@use "@carbon/react/scss/theme" as *;

@use "./overrides";

.date-popover {
  .popover-content {
    animation: fadeInAnimation 0.3s ease-in-out forwards;
    // animation: slideInBottomAnimation 0.3 ease-in-out forwards;
    background-color: #fff !important;
  }
  .cds--popover--button {
    &::before {
      content: url("../../assets/icons/calendar.svg") !important;
      margin: 10px;
      position: relative;
      top: 5px !important;
    }
    cursor: pointer;
    background-color: transparent !important;
    border: none !important;
    border-top: solid 1px lightgray !important;
    border-right: solid 1px lightgray !important;
    border-bottom: solid 1px lightgray !important;
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    color: black !important;
    padding: 4px !important;
    font-size: 14px !important;
    text-align: center !important;
    padding-right: 20px !important;
    font-family: Catamaran-Regular;
  }
  .cds--label {
    text-align: center !important;
    margin-bottom: 0.2rem;
  }
  .cds--date-picker__input {
    text-align: center !important;
    border: solid 1px lightgray !important;
  }

  .cds--date-picker--range .cds--date-picker-container,
  .cds--date-picker--range .cds--date-picker__input {
    background-color: white !important;
  }
}
