@use "../../shared-animation-styles" as *;

.summary-container {
  // height: fit-content;
  text-align: left;
  .number-align--right {
    text-align: right !important;
  }
  padding-top: 10px;
  h5 {
    margin-bottom: 20px;
  }
  td {
    font-size: 10px;
    font-weight: normal;
    border-top: solid 1px rgb(224, 224, 224);
    font-size: 15px;
    width: 100%;
    padding: 10px;
  }
  tr {
    line-height: 47px !important;
    width: 100%;

    &.data-row {
      &:hover {
        cursor: pointer;
        background-color: #7070700d;
        @include prop-transition(background-color, 0.5s);
      }
    }
  }
}

@media screen and (max-width: 620px) {
  .summary-container {
    margin-left: 0 !important;
    margin-top: 30px;
  }
}
