@use "@carbon/react/scss/spacing" as *;
@use "@carbon/react/scss/type" as *;
@use "@carbon/react/scss/breakpoint" as *;
@use "@carbon/react/scss/theme" as *;

@use "./overrides";

.SectionedHorizontalLinedTable {
  // height: fit-content;
  padding: 0px 0px 15px 0px;
  margin-bottom: 100px;
  overflow: hidden;
}
