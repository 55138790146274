@use "@carbon/react";
@use "@carbon/react/scss/breakpoint" as *;
@use "@carbon/react/scss/spacing" as *;
@use "@carbon/react/scss/theme" as *;

@use "./fonts" as *;
// @use "./app-colors" as *;
@use "./shared-styles" as *;

// override defaults
// $--cds-focus: #1899d7;

.cds--white {
  --cds-focus: #1899d7 !important;
}

.cds--content-switcher-btn {
  justify-content: center;
}

// .cds--btn--danger {
//   background-color: $switchapp-danger-color3;
// }

.cds--file__drop-container {
  border-radius: $spacing-03;
}

.cds--modal-container {
  @include breakpoint(md) {
    border-radius: 10px;
    min-width: fit-content;
    max-width: 100%;
  }

  .cds--modal-close:focus {
    border-color: $switchapp-themecolor;
    @include breakpoint(md) {
      border-top-right-radius: 10px;
    }
  }
}

.full-screen--modal {
  @include breakpoint(md) {
    .cds--modal-container {
      min-width: 60%;
      max-width: unset;
      width: unset;
    }
  }
}

body {
  font-family: Catamaran-Regular;

  // * {
  //   transition: width height 0.4s;
  // }
}

/// Remove overrides once Carbon bugs are fixed upstream.
/// Need grid option to not add page gutters at large viewports, to also use when nesting grids
/// @link https://github.com/carbon-design-system/carbon/issues/2792
@media (min-width: 42rem) {
  .cds--grid--no-gutter {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

/// Padding was introduced in 10.3.0, needs an option to let grid set the viewport gutter
/// @link https://github.com/carbon-design-system/carbon/issues/3010
.cds--content {
  padding: 0;
  background: var(--cds-background);
  margin-top: 5rem !important;
}

.cds--content .cds--css-grid {
  max-width: 100%;
  @include breakpoint(lg) {
    padding-left: 70px;
    padding-right: 70px;
  }
}

// normal tabs
.cds--tabs .cds--tabs__nav-item--selected {
  border-bottom: 4px solid $switchapp-themecolor;
}

.cds--popover-content {
  border-radius: $spacing-03;
}

// button tabs
.button-tabs {
  // selected
  .cds--tabs .cds--tabs__nav-item--selected {
    background-color: #0000000d;
    color: $switchapp-themecolor;
    border: 1px solid lightgray;

    &.cds--tabs__nav-link:focus,
    .cds--tabs .cds--tabs__nav-link:active {
      outline: 0;
      outline-offset: 0;
    }
  }

  .cds--tabs {
    button {
      border: 1px solid lightgray;
      margin: 0;
    }

    // margins
    :not(button:nth-child(1)) {
      margin-left: -2px;
    }

    // right borders
    :not(button:last-child) {
      border-right: 0;
    }

    // rounding
    button:nth-child(1) {
      border-top-left-radius: $spacing-04;
      border-bottom-left-radius: $spacing-04;
    }

    button:last-child {
      border-top-right-radius: $spacing-04;
      border-bottom-right-radius: $spacing-04;
    }
  }
}

.cds--toolbar-content {
  height: 2.5rem !important;
}

.cds--search.cds--toolbar-search-container-expandable {
  min-width: 60px;
  // max-width: 300px;
  border: 1px solid #eeea;
  background-color: rgb(230, 230, 230);
  border-radius: $spacing-04;
}

.cds--list-box {
  border-color: transparent;
}

.cds--label {
  font-size: 14px;
  font-family: Catamaran-semibold;
}

.switchapp-themed {
  &.hide-error-message {
    .cds--form-requirement {
      display: none !important;
    }
  }

  &.cds--text-input,
  &.cds--text__input {
    border: 1px solid lightgray;
    background-color: transparent;
    border-radius: $spacing-03;

    &.switchapp-small--input {
      max-width: 80px;
    }
  }

  .cds--text-area {
    border: 1px solid lightgray;
    background-color: transparent;
    border-radius: $spacing-03;
  }

  .cds--select-input {
    border: 1px solid lightgray !important;
    background-color: transparent;
    border-radius: $spacing-03;
  }

  &.cds--btn {
    padding: 0 $spacing-05;
    border-radius: $spacing-02;
    border-color: $layer-accent-01;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: unset;

    &.cds--btn--disabled {
      opacity: 0.6;
    }

    &.create-new-button {
      // make them smaller
      align-self: end;
      padding: 0.5rem 1rem;
      margin-right: $spacing-03;
    }

    .export-button--icon {
      margin-right: $spacing-02;
      color: $switchapp-themecolor;
    }

    &.export-button {
      margin-right: $spacing-04;
    }

    &.cds--btn--primary {
      background-color: $switchapp-themecolor;

      &:hover {
        background-color: $switchapp-themecolor--hover;
      }

      &.cds--btn--disabled {
        color: white;
      }
    }

    &.cds--btn--tertiary {
      color: black;

      &:hover {
        color: white;
        background-color: $switchapp-themecolor;

        .export-button--icon {
          color: white;
        }
      }
    }

    &.cds--btn--success {
      color: white;
      background-color: $switchapp-success-color5;

      &:hover {
        background-color: $switchapp-success-color4;
      }
    }
  }
}

// animate error display
.input-container {
  .cds--form-requirement {
    // display: none !important;
    transition: all 1s !important;
  }
}
