@use "@carbon/react/scss/spacing" as *;
@use "@carbon/react/scss/type" as *;
@use "@carbon/react/scss/breakpoint" as *;
@use "@carbon/react/scss/theme" as *;

@use "./overrides";

.switchapp-icon--button {
  background-color: transparent;
  border: transparent;
  padding: 0;
  cursor: pointer;
}
