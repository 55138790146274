@use "@carbon/react/scss/spacing" as *;
@use "@carbon/react/scss/type" as *;
@use "@carbon/react/scss/breakpoint" as *;
@use "@carbon/react/scss/theme" as *;

@use "./overrides";
@use "../../shared-animation-styles" as *;

.popover {
  .popover-content {
    padding: 5px 5px;
    border-radius: 5px;
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.2);
  }
}
