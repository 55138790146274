@use "@carbon/react/scss/spacing" as *;
@use "@carbon/react/scss/type" as *;
@use "@carbon/react/scss/breakpoint" as *;
@use "@carbon/react/scss/theme" as *;

@use "./overrides";
@use "../../shared-styles" as *;

.currency-display--container {
  word-break: keep-all;
}

.currency-symbol {
  font-family: sans-serif !important;
  margin-right: $spacing-01;
}

.currency-amount {
  text-align: right !important;
}
