@use "@carbon/react/scss/spacing" as *;
@use "@carbon/react/scss/type" as *;
@use "@carbon/react/scss/breakpoint" as *;
@use "@carbon/react/scss/theme" as *;

@use "./overrides";
@use "../../shared-styles" as *;
@use "../../shared-animation-styles" as *;

.switchapp-legal-and-contact-footer--container {
  // for md/lg display at bottom
  @include breakpoint(md) {
    position: absolute;
    bottom: 1rem;
  }

  .switchapp-footer--item {
    color: $switchapp-dark-color3;

    &:hover {
      color: $switchapp-dark-color2;
    }
  }
}
