@use "@carbon/react/scss/spacing" as *;
@use "@carbon/react/scss/type" as *;
@use "@carbon/react/scss/breakpoint" as *;
@use "@carbon/react/scss/theme" as *;

@use "./overrides";
@use "../../shared-styles" as *;

.switchapp-form--container {
  background-color: white;
  padding: $spacing-08 $spacing-05;
  margin: $spacing-04 0;

  @include breakpoint(md) {
    min-width: 400px;
    padding: $spacing-08 $spacing-08;
  }

  // add shadow to form container
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}
