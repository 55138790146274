@use "@carbon/react/scss/spacing" as *;
@use "@carbon/react/scss/type" as *;
@use "@carbon/react/scss/breakpoint" as *;
@use "@carbon/react/scss/theme" as *;

@use "./overrides";
@use "../../shared-styles" as *;

.general-search-bar--container {
  position: relative;
  margin-left: 5px;
  // overflow-y: auto;
}

.dropdown--container {
  position: absolute;
  display: none;
  padding: 0 10px; // to enable proper display of card shadows
  margin-top: -5px;
  margin-left: -50px;
  min-width: 300px;
  max-width: 50%;
  overflow-y: auto;

  @include breakpoint(md) {
    width: 180%;
  }

  @include breakpoint(lg) {
    width: 100%;
  }

  &.show-dropdown {
    display: block;
  }

  .card-container {
    // handle overflow
    max-height: 600px;
    overflow-y: auto;
    overflow-x: auto;
    flex-wrap: nowrap;
    @include useThinScrollbar();

    background-color: white;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);

    .empty-results--container {
      padding: $spacing-04 $spacing-02;
      color: gray;
    }

    .show-all-link--container {
      // position: fixed;
      // width: 100%;
      align-self: stretch;
      background-color: white;
      border-bottom: 1px solid lightgray;
      padding: $spacing-02 $spacing-04;
    }

    .general-search--link {
      text-decoration: none;

      &:not(.show-all--link) {
        color: rgb(49, 49, 49);
      }
    }

    .search-link--container {
      padding: 2.5px 5px;
      margin: 2.5px 0;
      border-radius: 2.5px;
      background-color: #ccc2;

      .general-search--date {
        padding: 2px 2.5px;
        color: rgba(69, 129, 241, 0.933);
        font-weight: bolder;
      }

      .badge-container {
        margin-top: 5px;

        &.success-badge--color {
          background-color: #72e29fce;
          color: black;
        }
      }

      &.pattern-search--helper {
        cursor: pointer;
      }

      &:hover {
        background-color: rgba(59, 124, 255, 0.8);
        // background-color: $switchapp-themecolor !important;

        color: white;

        .gateway-display {
          border: 1px solid rgb(18, 186, 186);
        }

        .general-search--date {
          color: white;
        }
      }

      .gateway-display {
        border: 1px solid lightgray;
        color: $switchapp-themecolor;
        background-color: #fffe;
        padding: 2px 5px;
        display: inline-flex;
        border-radius: 3px;

        .gateway-icon--tiny {
          width: 15px;
          height: 15px;
          margin-right: 3px;
        }
      }
    }

    .search-section--container {
      margin-top: 10px;
      // margin-top: 25px; // for fixed show all results

      .search-section--header {
        color: gray;
      }
    }
  }
}
