@use "@carbon/react/scss/spacing" as *;
@use "@carbon/react/scss/type" as *;
@use "@carbon/react/scss/breakpoint" as *;
@use "@carbon/react/scss/theme" as *;

@use "./overrides";

.section-subheader--container {
  margin-top: $spacing-04;
  margin-bottom: $spacing-02;
}
