@use "@carbon/react/scss/breakpoint" as *;
@use "@carbon/react/scss/spacing" as *;
@use "@carbon/react/scss/type" as *;
@use "@carbon/react/scss/theme" as *;

@use "./shared-animation-styles" as *;

// switchapp style variables
$switchapp-themecolor: rgb(59, 124, 255); // #1899d7;
$switchapp-light-theme: #cc91ba0f; // #1899d70f;
$switchapp-themecolor--hover: rgb(41, 87, 180); // #1b5a77;
$switchapp-border-color: rgb(230, 230, 230);
$switchapp-title--dark: rgb(76, 75, 75);

// themeing for ADMIN HEADER
$switchapp-admin-header-theme: rgba(59, 124, 255, 0.5); // #1899d70f;

// status colors
$switchapp-warning-color1: #f3f8a534;
$switchapp-warning-color2: #ebfc05;
$switchapp-warning-color3: #eef769e2;
$switchapp-warning-color4: rgba(252, 238, 188, 0.8);

$switchapp-danger-color1: rgb(253, 244, 244);
$switchapp-danger-color2: #de1e1e33;
$switchapp-danger-color3: rgb(223, 64, 39);

$switchapp-success-color1: #9ae4b834;
$switchapp-success-color2: #22c66334;
$switchapp-success-color3: #22c663;
$switchapp-success-color4: #3a6f50;
$switchapp-success-color5: #3ca466;

$switchapp-primary-color1: #d0e2ff34;
$switchapp-primary-color2: #d0e2ff;
$switchapp-primary-color3: #3b86ff;

$switchapp-secondary-color1: #7070701a;
$switchapp-secondary-color2: #70707033;
$switchapp-secondary-color3: #585757d5;

$switchapp-dark-color1: #39393934;
$switchapp-dark-color2: #393939;
$switchapp-dark-color3: #707070cc;

$switchapp-teal-color1: #9ef0f034;
$switchapp-teal-color2: #9ef0f0;
$switchapp-teal-color3: #259c9c;

// to prevent wrapping
$datatable-minwidth: 1200px;

// shared mixins

@mixin scaleElement($scaleSize) {
  zoom: $scaleSize;
  transform: scale($scaleSize);
  -moz-transform: scale($scaleSize);
  transform-origin: 0 0;
  -moz-transform-origin: 0 0;
}

@mixin usePaddingForMediumScreen($paddingV, $paddingH) {
  @include breakpoint(md) {
    padding: $paddingV $paddingH;
  }
}

@mixin usePaddingForLargeScreen($paddingV, $paddingH) {
  @include breakpoint(lg) {
    padding: $paddingV $paddingH;
  }
}

@mixin useThinScrollbar(/*$scrollbarWidth*/) {
  /* width */
  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 5px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: lightgray;
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: gray;
  }
}

// start border identify helpers

.red-border--show {
  border: 1px solid red;
}

.green-border--show {
  border: 1px solid green;
}

.blue-border--show {
  border: 1px solid blue;
}

.purple-border--show {
  border: 1px solid purple;
}

.yellow-border--show {
  border: 1px solid yellow;
}

// end border identify helpers

.text-success {
  color: $switchapp-success-color3;
}
.text-warning {
  color: $switchapp-warning-color3;
}
.text-danger {
  color: $switchapp-danger-color3;
}
.text-primary {
  color: $switchapp-primary-color3;
}
.text-secondary {
  color: $switchapp-secondary-color3;
}
.text-dark {
  color: $switchapp-dark-color3;
}
.text-teal {
  color: $switchapp-teal-color3;
}

// start flex-helpers
.flex-container {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  flex-wrap: wrap;

  &.flex-break--small {
    @include breakpoint(sm) {
      display: block;
    }
  }

  &.flex-break--medium {
    @include breakpoint(md) {
      display: block;
    }
  }

  @include breakpoint(md) {
    // flex-wrap: nowrap;
    &.flex-break--small {
      display: flex;
    }
  }

  @include breakpoint(lg) {
    &.flex-break--medium {
      display: flex;
    }
  }

  &.col {
    flex-direction: column;
  }

  &.row-reverse {
    flex-direction: row-reverse;
  }
}

.flex-box {
  flex: 1;

  &.flex-1_2 {
    flex: 1.2;
  }

  &.flex-1_5 {
    flex: 1.5;
  }

  &.flex-2 {
    flex: 2;
  }
}

.no-flex {
  flex: 0;
}

.no-wrap {
  flex-wrap: nowrap;
}

.flex-divider--vertical {
  flex: 0 1 auto;

  @include breakpoint(md) {
    width: 1px;
    margin: 0 $spacing-04;
  }

  @include breakpoint(lg) {
    margin: 0 $spacing-05;
  }
  background-color: lightgray;
}

.flex-divider--horizontal {
  flex: 1 auto;
  max-height: 1px;

  @include breakpoint(md) {
    max-height: 1px;
  }
  background-color: lightgray;
}

.overflow-auto {
  overflow: auto;
}

.justify-content--center {
  justify-content: center;
}

.justify-content--between {
  justify-content: space-between;
}

.justify-content--start {
  justify-content: flex-start;
}

.justify-content--end {
  justify-content: flex-end;
}

.align-items--center {
  align-items: center !important;
}

.align-items--start {
  align-items: flex-start !important;
}

.align-items--end {
  align-items: flex-end !important;
}

.align-items--baseline {
  align-items: baseline !important;
}

.align-items--stretch {
  align-items: stretch !important;
}

.align-content--center {
  align-content: center;
}

.align-content--between {
  align-content: space-between;
}

.align-content--start {
  align-content: flex-start;
}

.align-content--end {
  align-content: flex-end;
}

.align-content--stretch {
  align-content: stretch;
}

// ---- self

.align-self--center {
  align-self: center;
}

.align-self--end {
  align-self: flex-end;
}

.align-self--stretch {
  align-self: stretch;
}

// end flex-helpers

// margin helpers

// 3 units
.mr-3 {
  margin-right: $spacing-02 !important;
}

.ml-3 {
  margin-left: $spacing-02 !important;
}

// 5 units

.mt-5 {
  margin-top: $spacing-04 !important;
}

.mb-5 {
  margin-bottom: $spacing-04 !important;
}

.mr-5 {
  margin-right: $spacing-04 !important;
}

.ml-5 {
  margin-left: $spacing-04 !important;
}

.mx-5 {
  margin-left: $spacing-04 !important;
  margin-right: $spacing-04 !important;
}

.my-5 {
  margin-top: $spacing-04 !important;
  margin-bottom: $spacing-04 !important;
}

// 10 units

.mt-10 {
  margin-top: $spacing-08 !important;
}

.mb-10 {
  margin-bottom: $spacing-08 !important;
}

.mr-10 {
  margin-right: $spacing-08 !important;
}

.ml-10 {
  margin-left: $spacing-08 !important;
}

.mx-10 {
  margin-left: $spacing-08 !important;
  margin-right: $spacing-08 !important;
}

.my-10 {
  margin-top: $spacing-08 !important;
  margin-bottom: $spacing-08 !important;
}

@include breakpoint(md) {
  .mt-md-5 {
    margin-top: $spacing-04 !important;
  }
  .mb-md-5 {
    margin-bottom: $spacing-04 !important;
  }
  .mr-md-5 {
    margin-right: $spacing-04 !important;
  }
  .ml-md-5 {
    margin-left: $spacing-04 !important;
  }
}

// end margin helpers

// padding helpers

.pt-5 {
  padding-top: $spacing-04 !important;
}

.pb-5 {
  padding-bottom: $spacing-04 !important;
}

.pr-5 {
  padding-right: $spacing-04 !important;
}

.pl-5 {
  padding-left: $spacing-04 !important;
}

.px-5 {
  padding-right: $spacing-04 !important;
  padding-left: $spacing-04 !important;
}

.py-5 {
  padding-top: $spacing-04 !important;
  padding-bottom: $spacing-04 !important;
}

@include breakpoint(md) {
  .pt-md-5 {
    margin-top: $spacing-04 !important;
  }
  .pb-md-5 {
    margin-bottom: $spacing-04 !important;
  }
  .pr-md-5 {
    margin-right: $spacing-04 !important;
  }
  .pl-md-5 {
    margin-left: $spacing-04 !important;
  }
}

// end padding helpers

// border helpers

.bordered {
  border: 1px solid $switchapp-border-color !important;
}

.b-r-3 {
  border-radius: 3px;
}

.b-r-5 {
  border-radius: 5px;
}

.bt {
  border-top: 1px solid $switchapp-border-color !important;
}

.bb {
  border-bottom: 1px solid $switchapp-border-color !important;
}

.br {
  border-right: 1px solid $switchapp-border-color !important;
}

.bl {
  border-left: 1px solid $switchapp-border-color !important;
}

@include breakpoint(md) {
  .bt-md {
    border-top: 1px solid $switchapp-border-color !important;
  }
  .bb-md {
    border-bottom: 1px solid $switchapp-border-color !important;
  }
  .br-md {
    border-right: 1px solid $switchapp-border-color !important;
  }
  .bl-md {
    border-left: 1px solid $switchapp-border-color !important;
  }
}

// end border helpers

// display hide helpers

.d-none {
  display: none !important;
}

.d-block {
  display: block !important;
}

.d-flex {
  display: flex !important;
}

@include breakpoint(md) {
  .d-md-none {
    display: none !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-flex {
    display: flex !important;
  }
}

@include breakpoint(lg) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
}

// end display hide helpers

.opacity-10 {
  opacity: 0.1;
}

.opacity-20 {
  opacity: 0.2;
}

.opacity-30 {
  opacity: 0.3;
}

.opacity-50 {
  opacity: 0.5;
}

.opacity-70 {
  opacity: 0.7;
}

.opacity-90 {
  opacity: 0.9;
}

.float-right {
  float: right;
}

div,
span {
  &.centered {
    margin: auto 5px !important;
  }
}

.switchapp-small {
  font-size: 12px !important;
  line-height: 1.5;
}

.align-icon--center {
  text-align: center;
  vertical-align: middle;
}

.text--center {
  text-align: center;
  vertical-align: middle;
}

.text--right {
  text-align: right;
}

.text--left {
  text-align: left;
}

.switchapp-legal-agreements {
  font-size: 14px;
  a {
    color: $switchapp-themecolor;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.switchapp-link {
  color: $switchapp-themecolor;
  text-decoration: none;
  // &:hover {
  //   text-decoration: underline;
  // }
}

.input-container {
  margin-top: $spacing-06;

  .icon--container {
    margin-right: $spacing-06;
  }

  .input-icon-right {
    float: right;
    margin-top: -30px;
  }
}

.switchapp-logo {
  width: 150px;

  // animation: flip90DegreesX 0.7s ease-in;
}

.gateway-logo {
  width: 40px;
  height: 40px;
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.gateway-logo-full {
  width: 116px;
}

.date--oneline {
  word-break: keep-all;
}

.truncate-text--ellipsis {
  // width: 200px; // set the width in the actual element
  display: inline-block !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

/* DAVID SETTINGS PAGES STYLES --- START */

.gap-1 {
  gap: 10px;
}

.gap-2 {
  gap: 20px;
}

.gap-3 {
  gap: 30px;
}

.gap-4 {
  gap: 40px;
}

.p-1 {
  padding: 10px;
}
.p-2 {
  padding: 20px;
}

.p-3 {
  padding: 30px;
}

.p-4 {
  padding: 40px;
}

.bg-white {
  background-color: white !important;
}

.bg-red {
  background-color: red;
}

.bg-blue {
  background-color: blue;
}

.bg-green {
  background-color: green;
}

.bg-yellow {
  background-color: yellow;
}

.bg-teal {
  background-color: teal;
}

.rounded-sm {
  border-radius: 7px;
}

.rounded-md {
  border-radius: 12px;
}

.rounded-lg {
  border-radius: 20px;
}

.border-sm {
  border: 1px solid lightgray;
}

.border-md {
  border: 1.5px solid lightgray;
}

.border-lg {
  border: 2px solid lightgray;
}

.mt--1 {
  margin-top: 10px;
}

.mt--2 {
  margin-top: 20px;
}

.mt--3 {
  margin-top: 30px;
}

.mt--4 {
  margin-top: 40px;
}
.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.size-100 {
  height: 100%;
  width: 100%;
}
.white-text {
  color: white;
}

.modal-sections-header {
  font-size: 23px;
  font-weight: 700;
  padding: 10px 0px !important;
}
.modal-section-top-bar {
  border-bottom: 1px solid rgba(128, 128, 128, 0.104);
  margin-top: 50px;
}

.close-button--container {
  float: right;

  .close-button {
    padding: 0 $spacing-01 !important;
    border-radius: $spacing-08 !important;
    border: 0;
  }
}

// for settings pages
.input-container.settings-checkbox,
.input-container.settings-select,
.input-container.settings-input,
.input-container.settings-toggle {
  margin-top: 0 !important;

  .cds--checkbox-label {
    font-size: 16px !important;
    // @include breakpoint(md) {
    //   padding-left: 20px !important;
    // }
  }

  .switchapp-themed .cds--select-input,
  .switchapp-themed.cds--text-input {
    background-color: white;
  }
}

// .checkbox-lr-section {
//   display: flex;
//   flex-direction: row;
//   justify-content: start;
//   align-items: center;
//   gap: 40px;
//   width: 100%;
//   font-size: 16px !important;

//   @include breakpoint(sm) {
//     gap: 20px;
//   }

//   @include breakpoint(md) {
//     gap: 40px;
//   }

//   @include breakpoint(lg) {
//     gap: 40px;
//   }
// }

/* DAVID SETTINGS PAGES STYLES --- END */
