@use "@carbon/react/scss/spacing" as *;
@use "@carbon/react/scss/type" as *;
@use "@carbon/react/scss/breakpoint" as *;
@use "@carbon/react/scss/theme" as *;

@use "../../../../../shared-animation-styles" as *;

.add-volume-pricing-form--container {
  animation: slideInTopAnimation 0.5s ease-in;
  padding-left: $spacing-04;
  padding-right: $spacing-04;
  padding-top: $spacing-04;
  padding-bottom: $spacing-04;

  .add-volume-pricing--form {
    border-radius: $spacing-02;
    // background-color: rgba(255, 196, 199, 0.504);

    &.add-payment-volume-pricing--form {
      background-color: rgba(216, 240, 244, 0.5);
    }

    &.add-payout-volume-pricing--form {
      background-color: rgba(222, 222, 222, 0.5);
    }

    &.add-topup-volume-pricing--form {
      background-color: rgba(252, 238, 188, 0.5);
    }

    &.add-transfer-volume-pricing--form {
      background-color: rgba(216, 244, 225, 0.5);
    }

    .volume-pricing--subsection {
      padding-left: $spacing-04;
      padding-right: $spacing-04;
      padding-top: $spacing-04;
      padding-bottom: $spacing-04;
    }
  }
}
