@use "@carbon/react/scss/spacing" as *;
@use "@carbon/react/scss/type" as *;
@use "@carbon/react/scss/breakpoint" as *;
@use "@carbon/react/scss/theme" as *;

// overflow menu button
.cds--overflow-menu {
  border-radius: 50%;
  border: 1px solid rgba(211, 211, 211, 0.7) !important;
}

.cds--overflow-menu-options {
  border-radius: 4px;

  .cds--overflow-menu-options__btn {
    padding: 0px $spacing-03 !important;
  }

  li:first-child {
    // border: 1px solid yellow;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  li:last-child {
    // border: 1px solid red;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}
