@use "@carbon/react/scss/spacing" as *;
@use "@carbon/react/scss/type" as *;
@use "@carbon/react/scss/breakpoint" as *;
@use "@carbon/react/scss/theme" as *;

@use "./overrides";

.section-header {
  display: flex;
  flex-direction: column;
  gap: 12px;
  // background-color: #fdfdff;
  padding-top: 14px;

  .main-head {
    display: flex;
    justify-content: start;
    align-items: center;
    height: fit-content;
    padding-left: 25px;
    padding-right: 25px;
    font-size: 20px;
    font-weight: 700;
    padding-top: 10px;

    @include breakpoint(sm) {
      padding-left: 10px;
      padding-right: 10px;
      padding-top: 10px;
    }

    @include breakpoint(md) {
      padding-left: 25px;
      padding-right: 25px;
      font-size: 20px;
      font-weight: 700;
      padding-top: 10px;
    }

    @include breakpoint(lg) {
      padding-left: 25px;
      padding-right: 25px;
      font-size: 20px;
      font-weight: 700;
      padding-top: 10px;
    }
  }
  .sub-head {
    display: flex;
    justify-content: start;
    align-items: center;
    height: fit-content;
    padding-left: 25px;
    padding-right: 25px;
    font-size: 15px;
    padding-bottom: 10px;

    @include breakpoint(sm) {
      padding-left: 10px;
      padding-right: 10px;
      padding-top: 10px;
    }

    @include breakpoint(md) {
      padding-left: 25px;
      padding-right: 25px;
      font-size: 15px;
      padding-bottom: 10px;
    }

    @include breakpoint(lg) {
      padding-left: 25px;
      padding-right: 25px;
      font-size: 15px;
      padding-bottom: 10px;
    }
  }

  &.has-border {
    border-bottom: 1px solid lightgray !important;
  }
}
