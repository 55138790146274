@use "@carbon/react/scss/breakpoint" as *;
@use "@carbon/react/scss/spacing" as *;
@use "./overrides";

@use "../../shared-animation-styles" as *;

.animated-chart--view > * {
  animation: fadeInAnimation 2s ease-in-out forwards;
}

.chart-link {
  color: rgb(49, 154, 215);
  text-decoration: none;
  font-size: 12px;

  &:hover {
    color: black;
  }
}

.chart-total {
  font-size: $spacing-06;
}

.chart-label {
  &:not(.chart-label--large) {
    font-size: 11px;
    margin-top: $spacing-02;
    color: grey;
  }
}

.chart-with-summary--container {
  margin-bottom: $spacing-06;
}

.big-chart {
  small {
    font-size: 12px;
  }
}

.pointers {
  small {
    font-size: 12px;
    color: grey;
  }
}

.chart-summary {
  margin-top: $spacing-06;

  @include breakpoint(md) {
    border-left: solid 1px rgb(222, 221, 221);
  }
  p {
    font-size: 13px;
    color: rgb(168, 166, 166);
  }
  h6 {
    font-weight: 900;
  }
  h4 {
    font-weight: 900;
  }
}
.row2 {
  border-top: solid 1px rgb(222, 220, 220);
  margin-top: $spacing-08;
  padding-top: $spacing-08;
}
