@use "./AccountModeSwitcher/account-mode-switcher";
@use "./ActivityBlocker/activity-blocker";
@use "./ActivityIndicator/activity-indicator";
@use "./AppHeader/app-header";
@use "./AppLayout/app-layout";
@use "./Badge/badge";
@use "./BankAccountResolver/bank-account-resolver";
@use "./BulletPoint/bullet-point";
@use "./Card/card";
@use "./CdsColumn/cds-column";
@use "./CdsGrid/cds-grid";
@use "./Chart/chart";
@use "./CopyButton/copy-button";
@use "./CurrencyDisplay/currency-display";
@use "./CustomNavLink/custom-nav-link";
@use "./DataLoadingView/data-loading-view";
@use "./DatePicker/date-picker";
@use "./DatePopOver/date-pop-over";
@use "./DeleteConfirmation/delete-confirmation";
@use "./DotSeparator/dot-separator";
@use "./ErrorBoundary/error-boundary";
@use "./ErrorOccuredView/error-occured-view";
@use "./FileUpload/file-upload";
@use "./FormContainer/form-container";
@use "./GeneralSearchBar/general-search-bar";
@use "./HeaderMenuDropDown/header-menu-drop-down";
@use "./HoverDropDown/hover-drop-down";
@use "./InfoIcon/info-icon";
@use "./LayoutContainers/layout-containers";
@use "./LinkUrlCopyDisplay/link-url-copy-display";
@use "./LinkWithIcon/link-with-icon";
@use "./ModalContainer/modal-container";
@use "./OverflowMenuDropDown/overflow-menu-drop-down";
@use "./PageBanner/page-banner";
@use "./PageTabNavLinks/page-tab-nav-links";
@use "./PasswordStrengthIndicator/password-strength-indicator";
@use "./PeriodFilter/period-filter";
@use "./PinCodeInput/pin-code-input";
@use "./Portal/portal";
@use "./ProtectedRouter/protected-route";
@use "./QuickSummary/quick-summary";
@use "./QuickTable/quick-table";
@use "./QuickTableFilterTabs/quick-table-filter-tabs";
@use "./SearchBar/search";
@use "./SectionHeader/section-header";
@use "./SectionSubheader/section-subheader";
@use "./Select/select";
@use "./SkeletonLoader/skeleton-loader";
@use "./SummaryTable/summary-table";
@use "./SwitchAppButton/switch-app-button";
@use "./SwitchAppContentSwitcher/switch-app-content-switcher";
@use "./SwitchAppIconButton/switch-app-icon-button";
@use "./SwitchAppInlineLoadingIcon/switch-app-inline-loading-icon";
@use "./SwitchAppLegalAndContactFooter/switch-app-legal-and-contact-footer";
@use "./SwitchAppLoadingIcon/switch-app-loading-icon";
@use "./SwitchAppPopover/switch-app-popover";
@use "./ToastNotification/toast-notification";
@use "./Toggle/toggle";
@use "./TransactionListDetail/transaction-list-detail";

@use "./SectionedHorizontalLinedTable/sectioned-horizontal-lined-table";
@use "./SectionedHorizontalLinedTable/SectionedHeader/sectioned-header";
@use "./SectionedHorizontalLinedTable/LeftRightSection/left-right-section";
@use "./SectionedHorizontalLinedTable/LeftRightSection/CheckboxLRSection/checkbox-lr-section";
@use "./SectionedHorizontalLinedTable/Slider/slider";
@use "./SectionedHorizontalLinedTable/ThreeLeveledSection/three-leveled-section";
@use "./SectionedHorizontalLinedTable/ExtendedLeftRightSection/extended-left-right-section";
@use "./CircleHeaderMenuDropDown/circle-header-menu-drop-down";
@use "./SectionedHorizontalLinedTable/GrayBackground/gray-background";
@use "./SectionedHorizontalLinedTable/GrayBackground/LRSection/lr-section";
@use "./DetailsModal/details-modal";
@use "./DetailsModal/ModalQuickInfoSection/modal-quick-info-section";
@use "./DetailsModal/ModalTopSection/modal-top-section";
@use "./DetailsModal/TopDownFlexedContainer/top-down-flexed-container";
@use "./DetailsModal/ModalSectionTitle/modal-section-title";
@use "./DetailsModal/LeftRightFlexedContainer/left-right-flexed-container";
@use "./DetailsModal/NoDataToSeeContainer/no-data-to-see-container";
@use "./DetailsModal/StructuredList/structured-list";

.notifications-container {
  z-index: 2147483647;
  position: fixed;
  right: 10px;
  top: 20px;
}
