.rv-xy-plot {
  background-color: rgb(248, 252, 254) !important;
}
.rv-xy-plot__axis__tick__text {
  display: none !important;
}
text {
  display: none !important;
}

.rv-xy-plot__series {
  fill: rgb(243, 250, 253) !important;
  stroke: rgb(136, 202, 236) !important;
}

.pointers {
  width: 100% !important;
}
