@use "@carbon/react/scss/spacing" as *;
@use "@carbon/react/scss/type" as *;
@use "@carbon/react/scss/breakpoint" as *;
@use "@carbon/react/scss/theme" as *;

@use "./overrides";
.left-right-card {
  width: 100%;
  margin: 10px 0px;
  div {
    font-size: 14px;
  }

  .key-text {
    font-weight: 600;
    width: 20%;
  }

  .value-text {
    font-size: 14px !important;
    max-width: 60%;
  }
}
