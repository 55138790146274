@use "@carbon/react/scss/spacing" as *;
@use "@carbon/react/scss/type" as *;
@use "@carbon/react/scss/breakpoint" as *;
@use "@carbon/react/scss/theme" as *;

@use "./overrides";
@use "../../shared-styles" as *;

.go-back--link {
  a {
    color: black !important;

    // &:hover {
    //   text-decoration: none !important;
    // }
  }
}
