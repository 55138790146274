@use "@carbon/react/scss/spacing" as *;
@use "@carbon/react/scss/type" as *;
@use "@carbon/react/scss/breakpoint" as *;
@use "@carbon/react/scss/theme" as *;

@use "./overrides";
/* Rounded sliders */
.gray-bg {
  background-color: #f6f6f6;
  border-radius: 10px;
  padding: 30px;
  height: 370px;
  height: fit-content;
}
