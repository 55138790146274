@use "@carbon/react/scss/spacing" as *;
@use "@carbon/react/scss/type" as *;
@use "@carbon/react/scss/breakpoint" as *;
@use "@carbon/react/scss/theme" as *;

@use "./overrides";

.section-header--container {
  margin-bottom: $spacing-04;

  .large-margin--bottom {
    margin-bottom: $spacing-08;
  }
}
