@use "@carbon/react/scss/spacing" as *;
@use "@carbon/react/scss/type" as *;
@use "@carbon/react/scss/breakpoint" as *;
@use "@carbon/react/scss/theme" as *;

.cds--structured-list-td {
  vertical-align: middle !important;
}

.cds--structured-list-td {
  padding: 0.5rem 0.5rem 0.5rem 0 !important;
}
