@use "@carbon/react/scss/spacing" as *;
@use "@carbon/react/scss/type" as *;
@use "@carbon/react/scss/breakpoint" as *;
@use "@carbon/react/scss/theme" as *;

@use "./overrides";
/* Rounded sliders */
.lr-bg {
  width: 100%;
  height: fit-content;
  justify-content: space-between;
  .left {
    width: 100%;
  }
  .right {
    width: 100%;
    height: 100%;
  }
  @include breakpoint(md) {
    .left {
      width: 45%;
    }
    .right {
      width: 55%;
      height: 100%;
    }
  }
}
