@use "@carbon/react/scss/spacing" as *;
@use "@carbon/react/scss/type" as *;
@use "@carbon/react/scss/breakpoint" as *;
@use "@carbon/react/scss/theme" as *;

@use "../../shared-styles";

.card-container {
  margin: $spacing-03 0;
  border: 2px solid shared-styles.$switchapp-border-color;
  border-radius: $spacing-04;

  // .card-header {
  // }

  // .card-footer {
  // }

  .card-content {
    padding: $spacing-04 $spacing-02;

    @include breakpoint(md) {
      padding: $spacing-04;
    }

    &.no-padding {
      padding: 0;
    }
  }
}
