@use "@carbon/react/scss/spacing" as *;
@use "@carbon/react/scss/type" as *;
@use "@carbon/react/scss/breakpoint" as *;
@use "@carbon/react/scss/theme" as *;

@use "../../shared-animation-styles" as *;

.active-tenant-display {
  padding: 5px 3px;
  background-color: azure;
  border-radius: 3px;
}

.page-tab--links {
  // &:not(&.small-screen--only) {
  //   padding-top: $spacing-06;
  //   padding-bottom: $spacing-04;
  // }
  padding-top: $spacing-06;
  padding-bottom: $spacing-04;
  // display: flex !important;
  // align-items: flex-end;

  &.small-screen--only {
    padding-top: $spacing-02;
    padding-bottom: $spacing-02;
  }

  .page-tab--underline {
    left: 0;
    right: 0;
    border-bottom: 1px solid lightgray;

    &:not(&.bounded-underline) {
      position: absolute;
    }
  }

  .merchant-id {
    vertical-align: bottom;
    color: black;
    font-family: Catamaran-SemiBold;
  }
}

.page-tab--nav-link {
  @include font-size-transition;

  color: black;
  text-decoration: none;
  margin-top: $spacing-06;
  padding-right: $spacing-06;
  font-family: Catamaran-SemiBold;

  @include breakpoint(lg) {
    margin-top: $spacing-08;
    padding-right: $spacing-08;
  }

  &.active {
    @include type-style("productive-heading-06");
    font-family: Catamaran-Bold;
  }
}

.link-name {
  display: "inline-block" !important;
}
