@use "@carbon/react/scss/spacing" as *;
@use "@carbon/react/scss/type" as *;
@use "@carbon/react/scss/breakpoint" as *;
@use "@carbon/react/scss/theme" as *;

@use "./overrides";

.text-input {
  position: relative;

  input[type="password"] {
    padding-right: 30px;
  }

  .show-password-toggle {
    border: none;
    background-color: transparent;

    position: absolute;
    right: 0;
    top: 38%;
    width: 30px;
    height: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
}
