@use "@carbon/react/scss/spacing" as *;
@use "@carbon/react/scss/type" as *;
@use "@carbon/react/scss/breakpoint" as *;
@use "@carbon/react/scss/theme" as *;

@use "../../shared-animation-styles" as *;

.with-header-border {
  .cds--modal-header {
    border-bottom: 1px solid lightgray;
  }
}

// animate modal height from loadingIcon to data display
// .cds--modal-container {
//   @include prop-transition(height, 5s);
// }
