@use "@carbon/react/scss/spacing" as *;
@use "@carbon/react/scss/type" as *;
@use "@carbon/react/scss/breakpoint" as *;
@use "@carbon/react/scss/theme" as *;

@use "./overrides";

.filter-text {
  @include type-style("productive-heading-01");
}

.quicktable-date-popover {
  .date-popover {
    button {
      height: 2.2rem !important;
      padding-left: 0;
    }
  }
}

.quicktable-period-filter {
  .period-filter--input {
    select {
      height: 2.2rem !important;
    }
  }
}

// .transaction-period--display {
//   // display: inline-block;

//   border: $spacing-01 solid $layer-accent-01;
//   border-left: 0;
//   border-top-right-radius: $spacing-03;
//   border-bottom-right-radius: $spacing-03;
//   padding: $spacing-02;
// }

.empty-table-display--container {
  min-height: 300px;
  background-color: #eaeaea;
}

.cds--data-table {
  .clickable-cell {
    cursor: pointer;
  }
}
