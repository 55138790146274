@use "@carbon/react/scss/spacing" as *;
@use "@carbon/react/scss/type" as *;
@use "@carbon/react/scss/breakpoint" as *;
@use "@carbon/react/scss/theme" as *;

@use "../../shared-styles";

.cds--tag {
  border-radius: $spacing-02 !important;
  margin-left: 0;
  &.cds--tag-green {
    background-color: shared-styles.$switchapp-success-color2 !important;
  }

  span {
    @include type-style("productive-heading-01");
    word-break: keep-all;
  }
}
