@use "@carbon/react/scss/spacing" as *;
@use "@carbon/react/scss/type" as *;
@use "@carbon/react/scss/breakpoint" as *;
@use "@carbon/react/scss/theme" as *;

@use "./overrides";

.bullet-point--container {
  margin-top: $spacing-06;

  .bullet-point--icon {
    margin-right: $spacing-05;
  }
  .bullet-point--title {
    font-size: 18px;
  }
  .bullet-point--description {
    max-width: 85%;
  }
}
