@use "@carbon/react/scss/spacing" as *;
@use "@carbon/react/scss/type" as *;
@use "@carbon/react/scss/breakpoint" as *;
@use "@carbon/react/scss/theme" as *;

@use "./overrides";
@use "../../shared-styles" as *;

.link-with-icon--container {
  .linkContainer {
    padding: $spacing-05 0;
    padding-left: $spacing-06;
    padding-right: $spacing-04;
    align-items: "center";
    justify-content: "space-between";
    cursor: pointer;

    &.disabled-link--button {
      cursor: not-allowed;
    }

    text-decoration: none;
    color: #000;

    &:hover {
      background-color: #7070700d;
    }

    border-bottom: 1px solid $switchapp-border-color;
  }

  .labelContainer {
    flex: 1;
    flex-direction: "row";
    align-items: "center";
  }

  .titleContainer {
    flex: 1;
    flex-direction: "column";
    align-items: "flex-start";
    justify-content: "center";
    margin-left: 15px;
  }

  .title {
    font-size: 16px;
  }

  .subtitle {
    font-size: 14px;
    opacity: 0.6;
    color: $switchapp-secondary-color3;
  }

  .iconRightContainer {
    padding: 0;
    margin-left: 10px;
  }

  .icon-left {
    border-radius: 50px;
    width: 30px;
    height: 30px;
  }

  .icon-right {
    width: 20px;
    height: 20px;
  }

  .icon-subscript {
    position: absolute;
    margin-top: 12px;
    margin-left: -18px;
  }
}
