@use "@carbon/react/scss/spacing" as *;
@use "@carbon/react/scss/type" as *;
@use "@carbon/react/scss/breakpoint" as *;
@use "@carbon/react/scss/theme" as *;

.page-banner--container {
  padding: $spacing-04 $spacing-04;
  padding-right: $spacing-02;
  border-radius: $spacing-04;
  margin-bottom: $spacing-06;
  background-image: linear-gradient(to bottom, transparent, #edebeb80);
  @include breakpoint(lg) {
    background-image: linear-gradient(to right, transparent, #edebeb80);
  }
}

.page-banner--icon {
  float: left;
  vertical-align: middle;
  margin-right: $spacing-02;
  height: 100%;
}

.page-banner--large-icon {
  width: $spacing-08;
  height: $spacing-08;
}

.page-banner--header {
  @include type-style("productive-heading-04");
  margin-bottom: $spacing-03;
}

.page-banner--subheader {
  @include type-style("productive-heading-03");
  margin-bottom: $spacing-03;
}

.page-banner--description {
  // font-size: 14px;
  @include type-style("productive-heading-01");
  font-weight: normal;
}

.page-banner--brand-ad {
  margin-top: $spacing-06;
  @include breakpoint(lg) {
    margin-top: 0;
  }
}
