@use "@carbon/react/scss/spacing" as *;
@use "@carbon/react/scss/theme" as *;

@use "../../shared-styles" as *;

// .cds--table-toolbar .cds--tabs {
//   margin-bottom: -$spacing-04;
// }

.cds--toolbar-content .cds--tabs {
  align-self: flex-end;
  margin-bottom: -$spacing-04;
}
