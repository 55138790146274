@use "@carbon/react/scss/spacing" as *;
@use "@carbon/react/scss/type" as *;
@use "@carbon/react/scss/breakpoint" as *;
@use "@carbon/react/scss/theme" as *;

@use "./overrides" as *;
@use "./ManageSpecialPricing/manage-special-pricing-form";

.summaries-container {
  .special-pricing--container {
    .payment-pricing--subsection {
      background-color: rgba(216, 240, 244, 0.5);
    }

    .payout-pricing--subsection {
      margin-top: $spacing-06;
      // background-color: rgb(255, 226, 226);
      background-color: rgba(222, 222, 222, 0.5);
    }

    .topup-pricing--subsection {
      margin-top: $spacing-06;
      background-color: rgba(252, 238, 188, 0.5);
    }

    .transfers-pricing--subsection {
      margin-top: $spacing-06;
      background-color: rgba(216, 244, 225, 0.5);
      // background-color: rgba(216, 244, 225, 0.8);
    }
  }
}
