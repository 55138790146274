@use "@carbon/react/scss/spacing" as *;
@use "@carbon/react/scss/theme" as *;

@use "../../shared-styles" as *;

// .cds--data-table-container {
//   min-width: $datatable-minwidth;
// }

.cds--table-toolbar {
  padding: $spacing-04;
  border-top-left-radius: $spacing-04;
  border-top-right-radius: $spacing-04;
}

.cds--pagination {
  border-bottom-left-radius: $spacing-04;
  border-bottom-right-radius: $spacing-04;
  &.cds--pagination--lg {
    // add space to bottom of page after table
    margin-bottom: $spacing-08;
  }
}
