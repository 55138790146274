@use "@carbon/react/scss/spacing" as *;
@use "@carbon/react/scss/type" as *;
@use "@carbon/react/scss/breakpoint" as *;
@use "@carbon/react/scss/theme" as *;

@use "./overrides";
.modal-top-section {
  overflow: hidden !important;
  .cancel-button-container {
    display: flex !important;
    justify-content: flex-end;
    align-items: flex-end;
    margin-top: $spacing-04;
  }

  .cancel-button-container svg {
    width: 30px;
    color: rgba(128, 128, 128, 0.586);
    // margin-bottom: 30px !important;
  }
  .actual-transaction-id {
    font-size: 14px !important;
  }
  .first-top-spacing {
    margin-top: 30px !important;
  }
  .modal-top {
    // border-bottom: 1px solid rgba(128, 128, 128, 0.231);
    padding: 0px;
    margin: 30px 0px 0px 0px;
    // height: $spacing-09;
    padding-bottom: 10px;
    vertical-align: middle !important;
  }
  .modal-header--title {
    font-size: 14px;
    font-weight: 600;
  }
  // .modal-header--subtitle {
  //   max-width: 50%;
  // }
  .copy-button--container {
    .copy-btn {
      display: flex !important;
      justify-content: flex-start;
      background-color: transparent !important;
      border: 0px !important;
      // align-items: flex-end;
      // width: 100% !important;
      // height: 100% !important;
      // padding: 0px !important;

      .popover-display {
        position: absolute;
        z-index: 99;
        margin-top: 10px;
        margin-left: 15px;
      }
      .copy-document--icon {
        width: 25px;
        height: 25px;

        margin-left: 3px;
      }
    }
  }
  .payment-container {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
  }
  .info-card {
    margin: 0px !important;
  }
  .top-section-bottom-next {
    margin: 0px !important;
  }
  .float-down {
    display: flex !important;
    justify-content: start;
    align-items: flex-end;
  }

  .modal-sub-heading {
    margin-top: $spacing-05;
  }
  .amount-text {
    font-size: 20px;
    font-weight: 700;
  }
  .transaction-id-text {
    display: flex !important;
    justify-content: start;
    align-items: flex-end;
  }
  .cds--subgrid {
    margin-left: 0px;
    margin-right: 0px;
  }
  // .cds--css-grid-column {
  //   margin: 0px;
  // }

  .cards-with-border {
    border-right: 2px solid rgba(128, 128, 128, 0.231);
  }

  .status-container {
    display: flex !important;
    justify-content: start;
    align-items: end;
  }

  .top-section-bottom {
    margin-top: $spacing-05;
  }

  // .sections {
  //   .card:not(:last-child) {
  //     border-right: 2px solid rgba(128, 128, 128, 0.205);
  //   }
  // }

  // .sections {
  //   .card:not(:first-child) {
  //     padding: 0px $spacing-04;
  //   }
  // }
  // .sections {
  //   div {
  //     padding: 0px $spacing-04;
  //   }
  // }
  .sections {
    height: 100px;
    padding-top: 20px;
  }

  .closeIcon {
    cursor: pointer;
  }
}
