@use "@carbon/react/scss/spacing" as *;
@use "@carbon/react/scss/type" as *;
@use "@carbon/react/scss/breakpoint" as *;
@use "@carbon/react/scss/theme" as *;

@use "./overrides";
.modal-section-title {
  padding: $spacing-03 0px;
  border-bottom: 1px solid rgba(128, 128, 128, 0.249);
  margin-top: 30px;

  .main-header {
    font-size: 18px;
    font-weight: 800;
  }
}
