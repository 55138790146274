@use "@carbon/react/scss/spacing" as *;
@use "@carbon/react/scss/type" as *;
@use "@carbon/react/scss/breakpoint" as *;
@use "@carbon/react/scss/theme" as *;

.hover-dropdown {
  position: relative;
  display: inline-block;
  padding-right: $spacing-05;

  &::after {
    position: absolute;
    top: 25%;
    right: $spacing-01;
    content: url("../../assets/icons/arrow-down.png");
  }
}

.hover-dropdown--button {
  @include type-style("productive-heading-01");
  border: none;
  background-color: transparent;
}

// .hover-dropdown--content {
//   display: none;
//   position: absolute;
//   background-color: #f1f1f1;
//   padding-top: $spacing-08;
//   min-width: 160px;
//   box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
//   z-index: 1;
// }

.hover-dropdown--content {
  display: none;
  position: absolute;
  margin-top: $spacing-02;
  // padding: $spacing-06 $spacing-04;
  width: $spacing-02 * 100;
  border-radius: $spacing-03;
  background-color: #fff;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 2;
}

.hover-dropdown:hover .hover-dropdown--content {
  display: block;
}
