@use "@carbon/react/scss/spacing" as *;
@use "@carbon/react/scss/type" as *;
@use "@carbon/react/scss/breakpoint" as *;
@use "@carbon/react/scss/theme" as *;

@use "./overrides";
/* Rounded sliders */
.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 26px;
  input {
    opacity: 0;
    width: 0;
    height: 0;
  }
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  &:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 3px;
    bottom: 3px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
}
input {
  &:checked {
    + {
      .slider {
        background-color: #2ca700;
        &:before {
          -webkit-transform: translateX(20px);
          -ms-transform: translateX(20px);
          transform: translateX(20px);
        }
      }
    }
  }
  &:focus {
    + {
      .slider {
        box-shadow: 0 0 1px #2ca700;
      }
    }
  }
}
.slider.round {
  border-radius: 34px;
  &:before {
    border-radius: 50%;
  }
}
