@use "@carbon/react/scss/spacing" as *;
@use "@carbon/react/scss/type" as *;
@use "@carbon/react/scss/breakpoint" as *;
@use "@carbon/react/scss/theme" as *;

.pin-input--container {
  .pin-value--container {
    margin-bottom: 10px;

    .pin-value--display {
      min-height: 50px;
      border: 2px solid grey;
      border-radius: 5px;
      min-width: 40%;
    }
  }

  .padded-input {
    margin-left: 30px;
    margin-right: 30px;
  }

  .button-row {
    margin-bottom: 5px;

    .pin-input--button {
      border: 1px solid grey;
      width: 50px;
      height: 50px;
      border-radius: 30px;
      cursor: pointer;

      &:hover {
        background-color: rgb(169, 167, 167);
      }
    }
  }
}
