@use "@carbon/react/scss/spacing" as *;
@use "@carbon/react/scss/type" as *;
@use "@carbon/react/scss/breakpoint" as *;
@use "@carbon/react/scss/theme" as *;

@use "./overrides";

.circle-header-menu-dropdown--container {
  .circle-header-menu-trigger--button {
    @include type-style("productive-heading-01");
    border: none;
    cursor: pointer;
    // padding-top: $spacing-03;
    // padding-bottom: $spacing-02;
    background-color: transparent;
    background-color: white;
    margin: $spacing-05 0;
    // border-radius: $spacing-02;

    border-radius: 50% !important;
    overflow: hidden !important;
    width: 32px !important;
    height: 32px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }

  .circle-header-menu-dropdown--content {
    position: absolute;
    padding: $spacing-06 $spacing-04;
    margin-top: -$spacing-04;
    width: 327px;
    border-radius: $spacing-03;
    background-color: #fff;
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 2;
    right: 5px;
  }

  .circle-header-menu-dropdown--button {
    @include type-style("productive-heading-01");
    border: none;
    width: 100% !important;
    // border-bottom: 1px solid #e6e6e6;
    // cursor: pointer;
    padding: $spacing-02 $spacing-02 $spacing-05 $spacing-02;
    background-color: transparent;
    margin: $spacing-02 0;

    .add-new-department--icon {
      margin-right: $spacing-04;
    }
  }
}
