@use "@carbon/react/scss/spacing" as *;
@use "@carbon/react/scss/type" as *;
@use "@carbon/react/scss/breakpoint" as *;
@use "@carbon/react/scss/theme" as *;

@use "./overrides.scss";

.user-businesses-dropdown {
  min-width: 300px;
  max-height: 500px;
  overflow-y: auto;
}

.add-new-business--button {
  padding: $spacing-04 0;
  margin: $spacing-02 0;
  border-radius: 3px;
  &:hover {
    background-color: #eee;
  }
}

#search-businesses {
  border: 1px solid #ddd !important;
  margin-bottom: 3px;
}

.app-logo {
  height: $spacing-06;
  margin-right: $spacing-02;
}

.header-name {
  @include type-style("productive-heading-03");
  font-weight: bolder;
}

.pad-bottom {
  padding-bottom: $spacing-08;
}

.user-accounts--container {
  padding: $spacing-06 $spacing-04;
}

.currency-select--header {
  .cds--select-input {
    background-color: white !important;
    max-height: 33px; // 35px;
    min-width: 80px;
    max-width: 100px;
    margin-left: 0 2.5px;
    padding-right: 5px !important;
  }

  label {
    margin-bottom: 0 !important;
  }
}

.account-select--container {
  background-color: white;
  margin: $spacing-05 0;
  border-radius: $spacing-02;
}

.account-select--container:hover {
  background-color: white;
  cursor: pointer;
}

.account-select--value {
  @include type-style("productive-heading-01");

  flex-wrap: nowrap;
  position: relative;
  width: 200px;
  max-height: 25px;
  overflow: hidden;

  .active-department-name {
    width: 85%;
    text-align: left;
  }

  .department-icon {
    max-height: 20px;
    max-width: 25px;
    margin-right: $spacing-02;
    vertical-align: auto;
  }

  &:after {
    content: url("../../assets/icons/arrow-down.png");
    position: absolute;
    right: -2px;
    margin-left: $spacing-03;
    vertical-align: middle;
  }
}

.department-view--icon {
  margin-right: $spacing-04;
  max-height: 24px;
}

.notification-view--icon {
  margin-right: $spacing-04;
  width: 30px;
  margin-bottom: 10px;
}

.notification-text {
  font-size: 12px !important;
  font-weight: 400;
  gap: 10px;
}

.user-email {
  font-size: 14px;
}

.notification-top {
  padding: 13px 0px;
  display: flex;
  justify-content: space-between;
  width: 100% !important;
  border-bottom: 1px solid #f5f5f5;
}
.user-buttons {
  height: 40px !important;
  display: flex !important;
  justify-content: start;
  align-items: center;
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
  border-bottom: 1px solid #f5f5f5;

  &.red {
    color: rgb(225, 1, 1);
  }
}
.user-buttons:hover {
  background-color: #f5f5f5 !important;
}
.user-top {
  padding: 13px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100% !important;
  border-bottom: 1px solid #f5f5f5;
}
.help-top {
  padding: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  width: 100% !important;
}
.help-container {
  display: inline-flex;
  gap: 10px !important;
}
.notification-head-text {
  font-size: 17px !important;
  font-weight: 700 !important;
}

.notification-sub-text {
  cursor: pointer;
  color: rgba(128, 128, 128, 0.445);
  font-weight: 200;
}

.help-title {
  display: flex;
  justify-content: start;
  cursor: pointer;
  color: rgba(128, 128, 128, 0.86);
  font-weight: 500;
  text-transform: uppercase;
  font-size: 13px;
}

.department-view--container {
  padding: 5px 0px;
  border-bottom: 1px solid #e6e6e6;

  .dept-name--container {
    flex-wrap: wrap;

    .department-name {
      @include type-style("productive-heading-01");
      font-weight: bolder;
      display: flex;
      text-align: left;
      justify-content: start;
      text-align: left;
      // align-items: center;
      max-width: 300px;
    }

    .department-id {
      @include type-style("productive-heading-01");
      font-size: 12px;
      color: #8c8c8c;
    }
  }
}

.department-view--container:hover {
  background-color: #f5f5f5 !important;
}

.cds--header__action:hover {
  background-color: transparent !important;
}

.header-icon-menu {
  border-radius: 50% !important;
  overflow: hidden !important;
  width: 32px !important;
  height: 32px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;

  // @include type-style("productive-heading-01");
  // border: none;
  // cursor: pointer;
  // // padding-top: $spacing-03;
  // // padding-bottom: $spacing-02;
  // background-color: transparent;
  // background-color: white;
  // margin: $spacing-05 0;
  // border-radius: $spacing-02;
}

.nav-section-desktop {
  display: flex;
  flex-direction: row;
  flex-grow: 1 !important;
  justify-content: space-around;
  align-items: center;
  flex-grow: 1 !important;
  margin-right: 100px;

  &.between {
    justify-content: space-between;
    flex-grow: 0 !important;
    gap: 20px !important;
    margin-right: 0px;
  }

  @include breakpoint(lg) {
    display: flex;
  }

  @include breakpoint(md) {
    display: flex;
  }

  @include breakpoint(sm) {
    display: none;
  }
}
.header-search-input {
  width: 60%;

  .search {
    input {
      border: 1.5px solid rgb(234, 234, 234) !important;
      // border-bottom: 1.5px solid lightgray !important;
      // height: 36px !important;
    }
  }
}

.header-icon-right {
  border: 1.5px solid rgb(234, 234, 234);
  width: 32px;
  height: 32px;
  border-radius: 50%;
}

.padded-sub-help-list {
  padding: 0px 10px !important;
  gap: 10px;
  margin-top: 10px;
  a {
    gap: 10px;
    text-decoration: none;
    color: blue;
    svg {
      width: 20px;
    }
  }
}
