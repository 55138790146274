@use "@carbon/react/scss/spacing" as *;
@use "@carbon/react/scss/type" as *;
@use "@carbon/react/scss/breakpoint" as *;
@use "@carbon/react/scss/theme" as *;

.date-range--picker {
  .date-range--start {
    &::before {
      content: url("../../assets/icons/calendar.svg") !important;

      //   display: inline-block;
      position: absolute;
      z-index: 10;
      margin-top: 10px;
      margin-left: 10px;
    }

    .cds--date-picker__input {
      background-color: transparent !important;
      border: none !important;
      border: solid 1px lightgray !important;

      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      color: black !important;
      padding-left: $spacing-08 !important;
      font-family: Catamaran-Regular;
    }
  }

  .date-range--end {
    display: none;
  }
}

.cds--date-picker__icon {
  display: none;
}

@media screen and (max-width: 620px) {
  .cds--date-picker__input {
    margin-left: 0px !important;
  }
}
