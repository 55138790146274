@use "@carbon/react/scss/spacing" as *;
@use "@carbon/react/scss/type" as *;
@use "@carbon/react/scss/breakpoint" as *;
@use "@carbon/react/scss/theme" as *;

@use "./overrides";
@use "../../../shared-styles" as *;

.payment-details-container {
  // .sub-header {
  //   span {
  //     display: flex;
  //     flex-direction: row;
  //     row-gap: 40px;
  //     gap: 30px;
  //   }
  // }
  .sections {
    margin-top: 20px !important;
  }
  .cds--modal-content {
    @include usePaddingForMediumScreen(10px, 30px);
  }

  .sub-header {
    gap: 20px !important;
  }

  .gateway-image {
    width: 50% !important;
  }
  .btn-container {
    .done--btn {
      background-color: transparent !important;
      cursor: pointer;
      border: 1px solid rgba(3, 3, 3, 0.427);
      border-radius: 3px;
      padding: 3px 3px;
    }
  }
}
