@use "@carbon/react/scss/spacing" as *;
@use "@carbon/react/scss/type" as *;
@use "@carbon/react/scss/breakpoint" as *;
@use "@carbon/react/scss/theme" as *;

@use "./overrides";

.password-strength-indicator--container {
  float: left;
  padding-left: 5px;
  // transition: display 2s;

  &.hidden {
    display: none;
    // animation: slideOutRightAnimation 0.5s ease-in forwards;
  }

  &.shown {
    display: block;
    animation: slideInLeftAnimation 0.5s ease-in;
  }

  .validation-entry--container {
    &.valid {
      .validation-indicator {
        border-color: #3db75e;
      }

      .validation-description {
        color: black;
      }
    }

    &.invalid {
      .validation-indicator {
        border-color: red;
      }

      .validation-description {
        color: #a4a59a;
      }
    }

    .validation-indicator {
      width: 8px;
      height: 8px;
      display: inline-block;
      border-style: solid;
      border-width: 4px;
      border-radius: 100%;
      margin-right: $spacing-03;
      font-weight: bolder !important;
    }
  }
}
