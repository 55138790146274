@use "@carbon/react/scss/spacing" as *;
@use "@carbon/react/scss/type" as *;
@use "@carbon/react/scss/breakpoint" as *;
@use "@carbon/react/scss/theme" as *;

@use "./overrides";

.file-upload--container {
  padding-top: $spacing-06;
  padding-bottom: $spacing-06;
  border-radius: $spacing-03;
}
