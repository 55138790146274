@use "@carbon/react/scss/spacing" as *;
@use "@carbon/react/scss/type" as *;
@use "@carbon/react/scss/breakpoint" as *;
@use "@carbon/react/scss/theme" as *;

@use "../../../../shared-animation-styles" as *;
@use "./AddVolumePricingOptionForm/add-volume-pricing-option-form";

.manage-special-pricing--form {
  .pricing-subsection--container {
    .pricing-subsection--header {
      padding: $spacing-04;
      margin-bottom: 5px;
      border-bottom: 1px solid lightgray;
      align-items: flex-end;

      .refresh-icon {
        width: 25px;
        max-height: 25px;
      }
    }

    &.payments-pricing--subsection {
      background-color: rgba(216, 240, 244, 0.5);
    }

    &.payouts-pricing--subsection {
      margin-top: $spacing-06;
      // background-color: rgb(255, 226, 226);
      background-color: rgba(222, 222, 222, 0.5);
    }

    &.topups-pricing--subsection {
      margin-top: $spacing-06;
      background-color: rgba(252, 238, 188, 0.5);
    }

    &.transfers-pricing--subsection {
      margin-top: $spacing-06;
      background-color: rgba(216, 244, 225, 0.5);
    }

    .default-pricing--subsection {
      .pricing-subsection--body {
        padding-left: $spacing-06;
        padding-right: $spacing-06;
        padding-top: $spacing-06;
        padding-bottom: $spacing-06;

        margin-bottom: $spacing-04;
        border-radius: $spacing-04;
      }
    }

    .volume-pricing--subsection {
      animation: slideInBottomAnimation 0.5s ease-in;
      background-color: rgba(100, 100, 100, 0.1);
      border: 1.5px dashed rgba(255, 0, 0, 0.3);

      .volume-pricing--row,
      .volume-pricing--row &span {
        font-size: 50px !important; // TODO: use large font here
      }
    }
  }
}
