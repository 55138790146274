@use "@carbon/react/scss/spacing" as *;
@use "@carbon/react/scss/type" as *;
@use "@carbon/react/scss/breakpoint" as *;
@use "@carbon/react/scss/theme" as *;

@use "./overrides";

.checkbox-lr-section {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  gap: 40px;
  width: 100%;

  @include breakpoint(sm) {
    gap: 20px;
  }

  @include breakpoint(md) {
    gap: 40px;
  }

  @include breakpoint(lg) {
    gap: 40px;
  }
}
