@use "@carbon/react/scss/spacing" as *;
@use "@carbon/react/scss/type" as *;
@use "@carbon/react/scss/breakpoint" as *;
@use "@carbon/react/scss/theme" as *;

.quicksummary {
  padding: $spacing-02 0;
  height: auto;
  min-height: 55px;

  // padding: 0 $spacing-04;
  // @include breakpoint(lg) {
  //   padding: 0 $spacing-06;
  // }

  @include breakpoint(md) {
    min-height: 70px;
  }

  .quicksummary-icon--container {
    padding-left: $spacing-02;

    @include breakpoint(lg) {
      padding-left: $spacing-02;
    }
  }

  .title-container {
    display: inline-flex;
    word-break: keep-all;
  }
}
