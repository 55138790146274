@use "@carbon/react/scss/spacing" as *;
@use "@carbon/react/scss/type" as *;
@use "@carbon/react/scss/breakpoint" as *;
@use "@carbon/react/scss/theme" as *;

@use "./overrides";
.modal-quickinfo-section {
  .section-row {
    align-items: center;
    height: $spacing-09;
    width: 100%;
    font-size: 15px;

    .section-key {
      width: 30%;
      font-weight: 600;
    }

    .section-value {
      width: 70%;
    }
  }
}
