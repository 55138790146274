// switchapp animation variables
$switchapp-font-anim--duration: 0.2s;
$switchapp-image-anim--duration: 0.2s;

$slide-animation--duration: 0.8;

@mixin font-size-transition($duration: $switchapp-font-anim--duration) {
  // animation
  -webkit-transition: font-size $duration !important;
  -moz-transition: font-size $duration !important;
  -o-transition: font-size $duration !important;
  transition: font-size $duration !important;
}

@mixin prop-transition($prop, $duration: $switchapp-font-anim--duration) {
  // animation
  -webkit-transition: $prop $duration !important;
  -moz-transition: $prop $duration !important;
  -o-transition: $prop $duration !important;
  transition: $prop $duration !important;
}

@mixin show-image-transition($duration: $switchapp-image-anim--duration) {
  // animation
  // -webkit-transition: font-size $duration;
  // -moz-transition: font-size $duration;
  // -o-transition: font-size $duration;
  // transition: font-size $duration;
}

@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content;
  }

  @-moz-keyframes #{$name} {
    @content;
  }

  @-ms-keyframes #{$name} {
    @content;
  }

  @keyframes #{$name} {
    @content;
  }
}

@include keyframes(fadeInAnimation) {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@include keyframes(slideInLeftAnimation) {
  0% {
    opacity: 0;
    margin-left: -50px;
    padding-right: 50px;
  }
  100% {
    opacity: 1;
    margin-left: 0;
    padding-right: 0;
  }
}

@include keyframes(slideInTopAnimation) {
  0% {
    opacity: 0;
    margin-top: -50px;
  }
  100% {
    opacity: 1;
    margin-top: 0;
  }
}

@include keyframes(slideInBottomAnimation) {
  0% {
    opacity: 0;
    margin-top: 50px;
    margin-bottom: -50px;
  }
  100% {
    opacity: 1;
    margin-top: 0;
    margin-bottom: 0px;
  }
}

@include keyframes(slideInBottomAnimationWithAbsolutePositioning) {
  0% {
    opacity: 0;
    margin-top: 50px;
    position: absolute;
  }
  100% {
    opacity: 1;
    margin-top: 0;
    position: relative;
  }
}

@include keyframes(slideOutRightAnimation) {
  0% {
    opacity: 1;
    margin-left: 0;
    padding-right: 0;
  }
  90% {
    opacity: 0;
    margin-left: +50px;
    padding-right: -50px;
    overflow-x: hidden;
  }
  100% {
    display: none;
    overflow-x: hidden;
  }
}

@include keyframes(flip90DegreesX) {
  0% {
    transform: rotateX(90deg);
  }
  100% {
    transform: rotateX(0deg);
  }
}
