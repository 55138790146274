@use "@carbon/react/scss/spacing" as *;
@use "@carbon/react/scss/type" as *;
@use "@carbon/react/scss/breakpoint" as *;
@use "@carbon/react/scss/theme" as *;

@use "./overrides";

.copy-document--button {
  width: 1%;
  vertical-align: middle;
  display: table-cell;
  background-color: #ddd;
  border: 1px solid #ccc;
  padding: $spacing-01 $spacing-02;
  cursor: pointer;

  .popover-display {
    position: absolute;
    margin-top: 10px;
  }
  .popover-content {
    padding: 5px 7px;
  }
  .copy-document--icon {
    width: 18px;
    height: 18px;
  }
}
