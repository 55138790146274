@use "@carbon/react/scss/spacing" as *;
@use "@carbon/react/scss/type" as *;
@use "@carbon/react/scss/breakpoint" as *;
@use "@carbon/react/scss/theme" as *;

@use "../../shared-styles" as *;

.dashboard {
  padding-left: $spacing-10;
}

.account-mode--switcher {
  margin-right: $spacing-06;
  margin-top: -$spacing-03;
}

.main-tab--link {
  @include type-style("productive-heading-01");
  color: black;
  text-decoration: none;
  padding: $spacing-02 $spacing-03;
  border-radius: $spacing-02;

  // add margin for small screens using break
  // margin-bottom: $spacing-04;

  &:hover {
    background-color: #7070700d;
  }

  &.active {
    color: white;
    background-color: $switchapp-themecolor;
  }
}

.button--link {
  margin-right: $spacing-03;

  :hover {
    background-color: rgb(238, 238, 238);
    background-color: #7070700d;
  }
}

.page-content {
  margin-top: $spacing-04;

  @include breakpoint(md) {
    margin-top: $spacing-08;
  }
}

.more-content {
  margin-left: -$spacing-03;
  padding: $spacing-04 0;

  // apply bottom border to all links except last
  // &:not(:last-child .more-content--link) {
  //   .more-content--contents {
  //     border-bottom: 1px solid lightgray;
  //   }
  // }
}

.link-icon {
  padding: $spacing-01;
  background-color: #fffc;
  border-radius: $spacing-08;
}

.more-content--link {
  @extend .main-tab--link;

  display: block;
  padding: $spacing-03 $spacing-05;
  padding-bottom: 0;
  border-radius: 0;
}

.more-content--contents {
  padding-bottom: $spacing-03;

  // apply bottom border to all links except last: replace with above later
  &:not(.last) {
    border-bottom: 1px solid $switchapp-border-color;
  }

  .link-name {
    margin-left: $spacing-04;
  }
}
