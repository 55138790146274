@use "@carbon/react/scss/spacing" as *;
@use "@carbon/react/scss/type" as *;
@use "@carbon/react/scss/breakpoint" as *;
@use "@carbon/react/scss/theme" as *;

@use "./overrides";
@use "../../shared-styles" as *;

.badge-container {
  display: inline-flex;
  border-radius: $spacing-02 !important;
  padding: $spacing-01 $spacing-02;
  word-break: keep-all;
  font-size: 10px;

  span {
    @include type-style("productive-heading-01");
    word-break: keep-all;

    // use an after to insert the indicator
    // ::after {
    //   content: "xxxxxxxxxxx";
    //   display: inline-block;
    //   margin-left: $spacing-03;
    //   border-radius: $spacing-04;
    //   vertical-align: text-top;
    //   width: $spacing-04;
    //   height: $spacing-04;
    //   background-color: $switchapp-danger-color3;
    // }
  }
}

.badge-status--indicator {
  // padding: $spacing-02;

  display: inline-block;
  margin-left: $spacing-03;
  border-radius: $spacing-04;
  vertical-align: text-top;

  &.indicator-size--md {
    width: $spacing-04;
    height: $spacing-04;
  }
}

.badge-font--large {
  font-size: 12px;
}

// badge colors
.warning-badge--color {
  background-color: $switchapp-warning-color2;
  border: 1px solid $switchapp-warning-color2;
}
.notification-badge--color {
  background-color: $switchapp-warning-color4;
  border: 1px solid $switchapp-warning-color2;
}
.danger-badge--color {
  background-color: $switchapp-danger-color2;
  border: 1px solid $switchapp-danger-color2;
}
.success-badge--color {
  background-color: $switchapp-success-color2;
  border: 1px solid $switchapp-success-color2;
}
.primary-badge--color {
  background-color: $switchapp-primary-color2;
  border: 1px solid $switchapp-primary-color2;
}
.secondary-badge--color {
  background-color: $switchapp-secondary-color2;
  border: 1px solid $switchapp-secondary-color2;
}
.dark-badge--color {
  background-color: $switchapp-dark-color2;
  border: 1px solid $switchapp-dark-color2;

  span {
    color: #ffffff; // text color
  }
}
.teal-badge--color {
  background-color: $switchapp-teal-color2;
  border: 1px solid $switchapp-teal-color2;

  span {
    color: #004144; // text color
  }
}

// indicator colors
.warning-indicator--color {
  background-color: $switchapp-warning-color3;
}
.danger-indicator--color {
  background-color: $switchapp-danger-color3;
}
.success-indicator--color {
  background-color: $switchapp-success-color3;
}
.primary-indicator--color {
  background-color: $switchapp-primary-color3;
}
.secondary-indicator--color {
  background-color: $switchapp-secondary-color3;
}
.dark-indicator--color {
  background-color: $switchapp-dark-color3;
}
.teal-indicator--color {
  background-color: $switchapp-teal-color3;
}
