@use "@carbon/react/scss/spacing" as *;
@use "@carbon/react/scss/type" as *;
@use "@carbon/react/scss/breakpoint" as *;
@use "@carbon/react/scss/theme" as *;

@use "./overrides" as *;
.payments-page {
  .transaction-status {
    word-break: keep-all;
  }

  .status-indicator {
    display: inline-block;
    margin-left: $spacing-03;
    border-radius: $spacing-04;
    width: $spacing-04;
    height: $spacing-04;
  }

  .date--oneline {
    word-break: keep-all;
  }
}
