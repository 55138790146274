@use "@carbon/react/scss/spacing" as *;
@use "@carbon/react/scss/type" as *;
@use "@carbon/react/scss/breakpoint" as *;
@use "@carbon/react/scss/theme" as *;

@use "./overrides";

.left-right-section {
  display: flex;
  flex-direction: row;
  padding: 20px;
  gap: 30px;
  margin: 0px 20px;

  @include breakpoint(sm) {
    flex-direction: column;
    gap: 10px;
    padding: 20px 0px 20px 0px;
  }
  @include breakpoint(lg) {
    flex-direction: row;
    gap: 30px;
    padding: 20px 0px 20px 0px;
  }
  @include breakpoint(md) {
    flex-direction: row;
    gap: 30px;
    padding: 20px 0px 20px 0px;
  }

  .left-text {
    width: 20%;
    display: flex;
    justify-content: start;
    align-items: center;
    font-weight: 600;

    @include breakpoint(sm) {
      width: 100%;
    }

    @include breakpoint(md) {
      width: 20%;
    }

    @include breakpoint(lg) {
      width: 20%;
    }
  }
  .right-text {
    width: 80%;
    display: flex;
    justify-content: start;
    align-items: center;

    @include breakpoint(sm) {
      width: 100%;
    }

    @include breakpoint(md) {
      width: 80%;
    }

    @include breakpoint(lg) {
      width: 80%;
    }
  }

  &.has-border {
    border-bottom: 1px solid lightgray !important;
  }
}
