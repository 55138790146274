@use "@carbon/react/scss/spacing" as *;
@use "@carbon/react/scss/type" as *;
@use "@carbon/react/scss/breakpoint" as *;
@use "@carbon/react/scss/theme" as *;

@use "../../shared-styles" as *;

.cds--header {
  height: 4rem !important;
  background-color: $switchapp-admin-header-theme !important;

  &.header-transparency-cover {
    background-color: white !important;
  }

  @include breakpoint(md) {
    padding-left: $spacing-04;
    padding-right: $spacing-04;
  }
  @include breakpoint(lg) {
    padding-left: $spacing-08;
    padding-right: $spacing-08;
  }
}

.cds--header__menu-item {
  background-color: transparent !important;
  // &:not(&:hover) {
  //   background-color: transparent !important;
  // }
}

.cds--search-input {
  border-radius: 5px;
  border-bottom-width: 0 !important;
}

// cds--side-nav__navigation cds--side-nav cds--side-nav--expanded side-nav--mobile-only cds--side-nav--ux cds--side-nav--hidden
// .cds--side-nav.cds--side-nav--rail:not(.cds--side-nav--fixed):hover, .cds--side-nav--expanded
.cds--side-nav__navigation.cds--side-nav--expanded.side-nav--mobile-only {
  // hide side nav if viewport size increases while it is expanded
  @include breakpoint(lg) {
    width: 0 !important;
  }
}

// .header-menu-dropdown--container .header-menu-dropdown--content :hover {
//   background-color: transparent !important;
// }

.header-menu-dropdown--container .header-menu-dropdown--button {
  border: 0px !important;
}

.header-menu-dropdown--content {
  padding: 10px 10px !important;
}
