@use "@carbon/react/scss/spacing" as *;
@use "@carbon/react/scss/type" as *;
@use "@carbon/react/scss/breakpoint" as *;
@use "@carbon/react/scss/theme" as *;

@use "./overrides";
.card {
  padding: 10px 0px 40px 0px;
  gap: 10px !important;
  div {
    font-size: 14px;
  }

  .key-text {
    font-weight: 600;
  }

  .value-text {
    font-size: 14px !important;
  }
}
