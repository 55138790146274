@use "@carbon/react/scss/spacing" as *;
@use "@carbon/react/scss/type" as *;
@use "@carbon/react/scss/breakpoint" as *;
@use "@carbon/react/scss/theme" as *;

@use "./overrides";
@use "../../shared-styles" as *;

$slide-animation--duration: 0.8;

@keyframes flipFormIcon {
  0% {
    transform: rotate3d(1, 0, 0, 90deg);
  }
  100% {
    transform: rotate3d(1, 0, 0, 0deg);
  }
}

.auth-page--container {
  background-image: url("../../assets/images/globe-blue.png");
  // background-repeat: no-repeat;
  // background-size: contain;
  height: 100vh;

  & > * {
    animation: slideInTopAnimation 0.6s;
  }

  // .switchapp-logo {
  //   animation-delay: ($slide-animation--duration + 0.3) s;
  //   animation-duration: 0.5s;
  //   animation-name: slideInTopAnimation;
  //   animation-timing-function: ease-in;
  // }

  @include breakpoint(md) {
    background-size: cover;
  }

  .buttons-container {
    margin-top: $spacing-06;
  }

  .form-container__header {
    margin-bottom: $spacing-06;
    font-size: 22px;

    color: $switchapp-title--dark;
  }

  .form-container__footer {
    margin-top: $spacing-06;
  }

  .form-container__body {
    .password-box--container {
      position: relative;

      .forgot-password--link {
        position: absolute;
        top: 25px;
      }
    }
  }

  a {
    color: $switchapp-themecolor;
    text-decoration: none;

    &:hover {
      // text-decoration: underline;
      color: $switchapp-themecolor--hover;
    }
  }

  .company-name {
    color: gray;
  }
}
